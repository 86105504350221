<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">
            <div class="h-handle-button" slot="buttonGroup">
                <span>
                    <el-button type="primary" size="small" v-right-code="'RuleToOrderWT:Edit'" @click="addrule">新增</el-button>
                    <el-button type="primary" size="small" v-right-code="'Ruletoorderwt:Deletebyids'" @click="deleterule">删除</el-button>
                    <el-button type="primary" size="small" v-right-code="'Ruletoorderwt:Enableenablestatus'" @click="setAvailableStatus">启用</el-button>
                    <el-button type="primary" size="small" v-right-code="'Ruletoorderwt:Enableenablestatus'" @click="setDisabledStatus">停用</el-button>
                </span>
            </div>
            <p slot="elList">
                <el-table ref="projectListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange"
                    highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col, index, count)  in dataSource.ColDefs.BodyFieldParams" :key="index" show-overflow-tooltip
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                        :fixed="index == 0 && (!config || !config.isDetailDisplay)"
                        :min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
                        v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName === 'RuleName'"><span type="text" @click="rowClick(scope.row)"
                                    style="text-decoration:underline;color:#1874CD;cursor:pointer">{{
                                        scope.row[col.FieldName] }}</span></span>
                            <span v-else> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
export default {
    mounted() {
        this.Utils.lazy(() => {
            var _this = this;
            this.Event.$on("reloadRuleList", () => this.reloadPageList());
            this.Event.$on("onAddRule", () => this.addrule());
        });
        this.initialize();
    },
    data() {
        return {
            multipleSelection: [],
            queryParam: {
                PageIndex: 1,
                PageSize: 10,
            },
            dataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0
            },

        }
    },
    props: {
        config: {
            isDetailDisplay: false,
            isButtonlDisplay: false,
        },
        option: {},
    },
    methods: {
        onDataSourceChange(ds) {
            var _this = this;

            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;

            });

        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.$emit("onCheckRow", this.multipleSelection);
        },
        reloadPageList: function () {
            this.initialize();
        },
        initialize() {
            this.onPageChange(this.queryParam);
        },
        onPageChange(param) {
            var _this = this;
            this.queryParam = param;
            this.$ajax.query("omsapi/ruleToOrderWT/getPageList", "post", this.queryParam, data => {
                _this.dataSource = data;
            });
        },
        addrule() {
            this.$ajax.send("omsapi/ruleToOrderWT/getEmptyDto", "get", {}, (data) => {
                if (data.Result != null) {
                    data.Result.EnableStatus = data.Result.EnableStatus.toString();
                }
                this.onChangeEditDataSource(data.Result);
            });
        },
        deleterule() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选择规则.`, "error");
                return false;
            }
            var This = this;

            var ids = This.Utils.selectionsToArr(
                This.multipleSelection,
                "Id"
            );
            this.Utils.confirm({
                title: "确认",
                content: "是否删除规则"
            }, () => {

                this.$ajax.send("omsapi/ruleToOrderWT/deleteByIds", "post", ids, (data) => {
                    This.onPageChange(This.queryParam);
                    This.Utils.messageBox("操作成功.", "success");
                });
            }, () => {
                This.Utils.messageBox("已取消操作.", "info");
            });
        },
        setAvailableStatus() {
            var _this = this;
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选择数据.`, "error");
                return false;
            }

            var ids = _this.Utils.selectionsToArr(
                _this.multipleSelection,
                "Id"
            );

            _this.$confirm(`确定启用规则吗, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$ajax.send("omsapi/ruleToOrderWT/enableEnableStatus", "post", ids, (data) => {
                    if (data.IsSuccess) {
                        _this.Utils.messageBox(data.OperationDesc, "success");
                        _this.Utils.messageBox("启用成功.", "success");
                        _this.initialize();
                    } else {
                        _this.Utils.messageBox(data.OperationDesc, "error");
                        _this.Utils.messageBox("启用失败.", "success");
                    }
                });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        setDisabledStatus() {
            var _this = this;
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选择数据.`, "error");
                return false;
            }

            var ids = _this.Utils.selectionsToArr(
                _this.multipleSelection,
                "Id"
            );

            _this.$confirm(`确定停用规则吗, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$ajax.send("omsapi/ruleToOrderWT/deactivateEnableStatus", "post", ids, (data) => {
                    if (data.IsSuccess) {
                        _this.Utils.messageBox(data.OperationDesc, "success");
                        _this.Utils.messageBox("停用成功.", "success");
                        _this.initialize();
                    } else {
                        _this.Utils.messageBox(data.OperationDesc, "error");
                        _this.Utils.messageBox("停用失败.", "success");
                    }
                });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        rowClick(row) {
            this.$ajax.send("omsapi/ruleToOrderWT/getById", "get", { id: row.Id }, (data) => {
                if (data.Result != null) {
                    data.Result.EnableStatus = data.Result.EnableStatus.toString();
                }
                this.Event.$emit("clearEditruletoorderdisassemblyForm");
                this.onChangeEditDataSource(data.Result);
            });
        },
        onChangeEditDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeEditDataSource", data);
        },
    }
}
</script>